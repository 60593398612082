(function(w, d) {
    var reactions = {
        init: function () {
            var __tnt = w.__tnt || {},
                oForm = d.getElementById('reaction-form'),
                oFormSelector = d.querySelector("#reaction-form"),
                sUUID = oForm.getAttribute("data-uuid"),
                sFormInput = d.getElementById('user-reactions-hidden-type'), 
                sFormActionValue = d.getElementById('user-reactions-action'),  
                sStorage = localStorage.getItem("user-reaction-" + sUUID),
                sStorageTime = localStorage.getItem("time-reacted-" + sUUID),
                oCountWrapper = d.querySelector(".count-" + sStorage),
                oWrapper = d.querySelector(".reaction-wrapper"),
                aClasses = oWrapper.classList,
                bReactionClass = aClasses.contains("logged_in");
            
            // Send reaction
            function sendData() {
                var sFormData = new FormData(oForm),
                    sXHR = new XMLHttpRequest(),
                    sFormAction = oForm.getAttribute("action"),
                    sFormInput = d.getElementById('user-reactions-hidden-type');
                                 
                sXHR.open("POST", sFormAction);
                sXHR.send(sFormData);
                                 
                // TN tracking
                __tnt.trackEvent({
                    'network': 'Site',
                    'socialAction': sFormInput,
                    'url': w.location.href
                });
                                 
                // DMP tracking
                dmpReactionData()
            }
               
            // If requires logged in user    
            if(bReactionClass) {
                if( __tnt.user.loggedIn ){
                    if (d.querySelector('.logged-in-reminder')) {
                        d.querySelector('.logged-in-reminder').remove();
                    }
                 } else {
                     aClasses.add('static-height');
                     var oLabels = Array.from(d.querySelectorAll('.reaction-name'));
                     oLabels.forEach(function(i) {
                         i.classList.add("hide");
                     });
                     var sCurrentURL = w.location.href;
                     oFormSelector.onclick = function() {
                        w.location.href="/users/login?referer_url=" + sCurrentURL;
                     };
                 }
             }
    
             // Hide count if zero
             var oCounts = d.querySelectorAll('.reaction-count'), i;
             for (i = 0; i < oCounts.length; ++i) {
                 var iCount = oCounts[i];
                 if (iCount.innerHTML === "0") {
                     iCount.classList.add("hide");
                 }
             }
                 
             // Check local storage for previous vote and highlight
             if (sStorage) {
                 d.querySelector(".form-icons." + sStorage).style.backgroundColor = "rgba(0,0,0,.1)";
                 oCountWrapper.classList.remove("hide");
             }
                 
             // Setup reaction and send
             function emotionSelection(e) {
                 if (e.target !== e.currentTarget) {
                     
                     var oEmotion = e.target.getAttribute("data-user-reaction-emotion"),
                         oCountWrapper = d.querySelector(".count-" + oEmotion),
                         sUUID = oForm.getAttribute("data-uuid"),
                         oMessage = d.getElementById("reaction-form-message"),
                         sStorageUpdated = localStorage.getItem("user-reaction-" + sUUID),
                         oOldCountWrapper = d.querySelector(".count-" + sStorageUpdated),
                         iCount = parseInt(oCountWrapper.getAttribute("data-user-reaction-count")),
                         iNewCount = parseInt(oCountWrapper.innerHTML),
                         sTimeReactedNew = new Date().getTime();
                         
                     // If theres already been a reaction made
                     if (sStorageUpdated) {
                         if (oEmotion === sStorageUpdated){
                             // Do nothing when clicking on a reaction youve already voted for
                         } else if (oEmotion != sStorageUpdated) {
                             
                             // Remove previous vote and send
                             sFormActionValue.setAttribute("value", "remove");
                             sFormInput.setAttribute("value", sStorageUpdated);
                             sendData();
                             sFormActionValue.setAttribute("value", "add");
                                 
                             // Add new vote and send
                             sFormInput.setAttribute("value", oEmotion);
                             sendData();
                                 
                             // Remove storage item & highlight
                             localStorage.removeItem("user-reaction-" + sUUID);
                             localStorage.removeItem("time-reacted-" + sUUID);
                             d.querySelector(".form-icons." + sStorageUpdated).style.backgroundColor = "transparent";
                                 
                             // Show reacted message
                             oMessage.innerHTML = "<p>You reacted: " + oEmotion + "!</p>";
                             oMessage.classList.remove("hide");
                                 
                             // Hide message, highlight reaction
                             setTimeout(function(){
                                 oMessage.classList.add("hide");
                                 d.querySelector(".form-icons." + oEmotion).style.backgroundColor = "rgba(0,0,0,.1)";
                             }, 3000);
                                
                             // Remove old vote
                             if( (parseInt(oOldCountWrapper.innerHTML) -1) === 0 ){
                                 oOldCountWrapper.innerHTML = 0;
                                 oOldCountWrapper.classList.add("hide");
                             } else {
                                 oOldCountWrapper.innerHTML = (parseInt(oOldCountWrapper.innerHTML) - 1);
                             }
            
                             // Up Count on new vote
                             if(isNaN(iNewCount)) {
                                 oCountWrapper.innerHTML = 1;
                                 oCountWrapper.classList.remove("hide");
                             } else {
                                  oCountWrapper.innerHTML = (iNewCount + 1);
                                  oCountWrapper.classList.remove("hide");
                             }
                                 
                             // Set local storage items
                             localStorage.setItem("user-reaction-" + sUUID, oEmotion);
                             localStorage.setItem("time-reacted-" + sUUID, sTimeReactedNew);
                         }
                     } else {
                         // First reaction being made: set reaction, send
                         sFormInput.value = oEmotion; 
                         sendData();
                             
                         // Display message
                         oMessage.innerHTML = "<p>You reacted: " + oEmotion + "!</p>";
                         oMessage.classList.remove("hide");
                             
                         // Hide message, highlight reaction
                         setTimeout(function(){
                             oMessage.classList.add("hide");
                             d.querySelector(".form-icons." + oEmotion).style.backgroundColor = "rgba(0,0,0,.1)";
                         }, 3000);
                             
                         // Up Count
                         if(iCount) {
                             oCountWrapper.innerHTML = (iCount + 1);
                             oCountWrapper.classList.remove("hide");
                         } else {
                              oCountWrapper.innerHTML = 1;
                              oCountWrapper.classList.remove("hide");
                         }
                             
                         // Set local storage items
                         localStorage.setItem("user-reaction-" + sUUID, oEmotion);
                         localStorage.setItem("time-reacted-" + sUUID, sTimeReactedNew);
                     }
                 }
             }
                
             // Listen for click event
             if(bReactionClass){
                 if ( __tnt.user.loggedIn) {
                      oFormSelector.addEventListener("click", emotionSelection, false)
                  }
             } else {
                 oFormSelector.addEventListener("click", emotionSelection, false);
             }
             
             // Remove local storage count if one exists after cache has cleared (15 min)
             var updatedTime = new Date().getTime();
        
             if (sStorageTime){
                 if ((updatedTime - sStorageTime) > 1000 * 60 * 15) {
                     localStorage.removeItem("time-reacted-" + sUUID);
                     oCountWrapper.innerHTML = parseInt(oCountWrapper.getAttribute("data-user-reaction-count"));
                 } else {
                     if(isNaN(oCountWrapper.getAttribute("data-user-reaction-count"))) {
                         oCountWrapper.innerHTML = 1;
                     } else {
                         oCountWrapper.innerHTML = ((parseInt(oCountWrapper.getAttribute("data-user-reaction-count"))) + 1);
                     }
                 }
             }
         }
    }
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', function() {
            reactions.init();
        });
    } else {
        reactions.init();
    }
})(window, document);